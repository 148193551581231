import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';

// import {
//   animate,
//   style,
//   transition,
//   trigger,
//   state
// } from '@angular/animations';
import { Subscription } from 'rxjs';

// const cOpacity = 1;                         // number:0 - 1       Opacity of the quote header when shown
// const cFadeDelay = '300ms';                 // string:time        Fade in time of the quote
// const cWidthDelay = '500ms ease-in-out';    // string:time        Time for the quote border to expand
// const cBodyDelay = '300ms 800ms';           // string:time        Time and delay for the quote body to fade in
// const cMaxWidth = '1140px';                 // string:width       Maximum width of top border
// const cMinWidth = '0px';                    // string:width       Minimum width of top border
const cInitDelay = 300; // number:ms          Number of ms before showing the initial animation
const cHideOffset = 0; // number:pixels      Number of pixels below the top to hide quote
const cShowOffset = 75; // number:pixels      Number of pixels below the top to show quote

@Component({
  selector: 'ssc-top-tabs',
  templateUrl: './ssc-top-tabs.component.html',
  styleUrls: ['./ssc-top-tabs.component.scss']
  // animations: [
  //     trigger('quoteBorderAnim', [
  //       state('hidden, void', style({
  //           width: cMinWidth,
  //           opacity: 0
  //       })),
  //       state('show', style({
  //           width: cMaxWidth,
  //           opacity: cOpacity
  //       })),
  //       transition('hidden => show', [
  //         animate(cFadeDelay, style({ opacity: cOpacity })),
  //         animate(cWidthDelay, style({ width: cMaxWidth }))
  //       ]),
  //       transition('show => hidden', [
  //         animate('0ms', style({ width: cMinWidth, opacity: 0  }))
  //       ]),
  //     ]),
  //     trigger('quoteIconAnim', [
  //         state('hidden, void', style({
  //             opacity: 0
  //         })),
  //         state('show', style({
  //             opacity: cOpacity
  //         })),
  //         transition('hidden => show', [
  //           animate(cFadeDelay, style({ opacity: cOpacity }))
  //         ]),
  //         transition('show => hidden', [
  //           animate('0ms', style({ opacity: 0 }))
  //         ]),
  //     ]),
  //     trigger('quoteBodyAnim', [
  //         state('hidden, void', style({
  //             opacity: 0
  //         })),
  //         state('show', style({
  //             opacity: 1
  //         })),
  //         transition('hidden => show', [
  //           animate(cBodyDelay, style({ opacity: 1 }))
  //         ]),
  //         transition('show => hidden', [
  //           animate('0ms', style({ opacity: 0 }))
  //         ]),
  //     ])
  // ]
})
export class SscTopTabsComponent implements AfterViewInit, OnDestroy {
  private scrollSubscription: Subscription;

  state: string = 'hidden';
  selected: number = 1;

  @Input() names: any;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  // @Input() quote: string;
  // @Input() person: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private scrollDispatcher: ScrollDispatcher
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  setSelected(new_selected: number) {
    this.selected = new_selected;
    this.onChange.emit(new_selected);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = 'show';
      this.changeDetectorRef.detectChanges();
    }, cInitDelay);
    this.onChange.emit(this.selected);
  }

  private checkState(element: ElementRef) {
    const winScrollTop: number = element.nativeElement.scrollTop;
    const winOffsetHeight: number = element.nativeElement.offsetHeight;
    const elOffsetTop: number = this.el.nativeElement.offsetTop;
    const offset: number = this.state === 'show' ? cHideOffset : cShowOffset;

    this.state =
      elOffsetTop + offset < winScrollTop + winOffsetHeight ? 'show' : 'hidden';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}

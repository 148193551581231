import {
  Component,
  Input
} from '@angular/core';
@Component({
  selector: 'ssc-badge',
  templateUrl: './ssc-badge.component.html',
  styleUrls: ['./ssc-badge.component.scss']
})
export class SscBadgeComponent {
  @Input() link: string;
  @Input() image: string;
  @Input() text: string;

  constructor() {}

}

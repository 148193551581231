import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';

import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';
import { Subscription } from 'rxjs';

const cOpacity = 1; // number:0 - 1       Opacity of the divider when shown
const cFadeDelay = '300ms'; // string:time        Fade in time of the divider
const cWidthDelay = '500ms ease-in-out'; // string:time        Time for the divider wings to expand
const cMaxWidth = '450px'; // string:width       Maximum width of each wing
const cMinWidth = '0px'; // string:width       Minimum width of each wing
const cInitDelay = 300; // number:ms          Number of ms before showing the initial animation
const cHideOffset = 0; // number:pixels      Number of pixels below the top to hide divider
const cShowOffset = 35; // number:pixels      Number of pixels below the top to show divider

@Component({
  selector: 'ssc-divider',
  templateUrl: './ssc-divider.component.html',
  styleUrls: ['./ssc-divider.component.scss'],
  animations: [
    trigger('dividerAnim', [
      state(
        'hidden, void',
        style({
          width: cMinWidth,
          opacity: 0
        })
      ),
      state(
        'show',
        style({
          width: cMaxWidth,
          opacity: cOpacity
        })
      ),
      transition('hidden => show', [
        animate(cFadeDelay, style({ opacity: cOpacity })),
        animate(cWidthDelay, style({ width: cMaxWidth }))
      ]),
      transition('show => hidden', [
        animate('0ms', style({ width: cMinWidth, opacity: 0 }))
      ])
    ]),
    trigger('dividerIconAnim', [
      state(
        'hidden, void',
        style({
          opacity: 0
        })
      ),
      state(
        'show',
        style({
          opacity: 0.6
        })
      ),
      transition('hidden => show', [
        animate(cFadeDelay, style({ opacity: 0.6 }))
      ]),
      transition('show => hidden', [animate('0ms', style({ opacity: 0 }))])
    ])
  ]
})
export class SscDividerComponent implements AfterViewInit, OnDestroy {
  private scrollSubscription: Subscription;

  state: string = 'hidden';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private scrollDispatcher: ScrollDispatcher
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = 'show';
      this.changeDetectorRef.detectChanges();
    }, cInitDelay);
  }

  private checkState(element: ElementRef) {
    const winScrollTop: number = element.nativeElement.scrollTop;
    const winOffsetHeight: number = element.nativeElement.offsetHeight;
    const elOffsetTop: number = this.el.nativeElement.offsetTop;
    const offset: number = this.state === 'show' ? cHideOffset : cShowOffset;

    this.state =
      elOffsetTop + offset < winScrollTop + winOffsetHeight ? 'show' : 'hidden';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}

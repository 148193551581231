import browser from 'browser-detect';
import { Component, OnInit, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import {
  routeAnimations,
  AppState,
  LocalStorageService,
  selectSettingsStickyHeader,
  selectEffectiveTheme
} from '../core/core.module';

import { actionSettingsChangeAnimationsPageDisabled } from '../core/settings/settings.actions';

@Component({
  selector: 'ssc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  navigationSideMenu = [
    { link: 'home', label: 'ssc.menu.home' },
    { link: 'staff', label: 'ssc.menu.staff' },
    { link: 'to-play', label: 'ssc.menu.to-play' },
    { link: 'to-connect', label: 'ssc.menu.to-connect' },
    { link: 'lend-a-hand', label: 'ssc.menu.lend-a-hand' }
  ];

  stickyHeader$: Observable<boolean>;
  theme$: Observable<string>;

  hideHeader: boolean = true;
  hideFooter: boolean = true;

  constructor(
    private store: Store<AppState>,
    private storageService: LocalStorageService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.document.documentElement
          .getElementsByTagName('mat-sidenav-content')[0]
          .scrollTo({ top: 0 });

        if (evt.urlAfterRedirects === '/landing-page') {
          this.hideFooter = true;
          this.hideHeader = true;
        } else {
          this.hideFooter = false;
          this.hideHeader = false;
        }
      }
    });
  }
}

import {
  Component,
  ChangeDetectorRef,
  Input,
  HostListener
} from '@angular/core';

import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';

const cBorderWidthInit = '1px'; // string:px          Initial border width
const cBorderWidthHover = '2px'; // string:time        Hover border width
const cZIndexHover = 99; // number:any         z-index of picture on hover
const cScaleHover = '1.05'; // string:scale       Scale of picture on hover

@Component({
  selector: 'ssc-photo',
  templateUrl: './ssc-photo.component.html',
  styleUrls: ['./ssc-photo.component.scss'],
  animations: [
    trigger('photoAnim', [
      state(
        'none, void',
        style({
          'border-width': cBorderWidthInit,
          transform: 'scale(1)',
          'z-index': 'initial'
        })
      ),
      state(
        'mouseover',
        style({
          'border-width': cBorderWidthHover,
          transform: 'scale(' + cScaleHover + ')',
          'z-index': cZIndexHover
        })
      ),
      transition('none => mouseover', [
        animate(
          '100ms',
          style({
            transform: 'scale(' + cScaleHover + ')',
            'z-index': cZIndexHover
          })
        )
      ]),
      transition('mouseover => none', [
        animate(
          '100ms',
          style({
            transform: 'scale(1)',
            'z-index': 'initial'
          })
        )
      ])
    ])
  ]
})
export class SscPhotoComponent {
  @Input() src: string;
  @Input() photoId: number;

  state: string = 'none';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    this.state = 'mouseover';
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.state = 'none';
    this.changeDetectorRef.detectChanges();
  }
}

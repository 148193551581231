import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';

import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';
import { Subscription } from 'rxjs';

// const cOpacity = 1;                         // number:0 - 1       Opacity of the quote header when shown
const cFadeDelay = '300ms'; // string:time        Fade in time of the quote
// const cWidthDelay = '500ms ease-in-out';    // string:time        Time for the quote border to expand
// const cBodyDelay = '300ms 800ms';           // string:time        Time and delay for the quote body to fade in
// const cMaxWidth = '1140px';                 // string:width       Maximum width of top border
// const cMinWidth = '0px';                    // string:width       Minimum width of top border
// const cStaggerDelay = 100;
const cInitDelay = 300; // number:ms          Number of ms before showing the initial animation
const cHideOffset = 0; // number:pixels      Number of pixels below the top to hide quote
const cShowOffset = 75; // number:pixels      Number of pixels below the top to show quote
const cYTranslate = '50px'; // string:percent     Amount to slide up on show

@Component({
  selector: 'ssc-side-tabs',
  templateUrl: './ssc-side-tabs.component.html',
  styleUrls: ['./ssc-side-tabs.component.scss'],
  animations: [
    trigger('sideTabAnim', [
      state(
        'hidden, void',
        style({
          transform: 'translateY(' + cYTranslate + ')',
          opacity: 0
        })
      ),
      state(
        'show',
        style({
          transform: 'translateY(0%)',
          opacity: 1
        })
      ),
      transition(
        'hidden => show',
        [
          animate(
            cFadeDelay + ' {{delay}}ms ease-out',
            style({
              transform: 'translateY(0%)',
              opacity: 1
            })
          )
        ],
        { params: { delay: 0 } }
      ),
      transition('show => hidden', [
        animate(
          '0ms',
          style({
            transform: 'translateY(' + cYTranslate + ')',
            opacity: 0
          })
        )
      ])
    ])
  ]
})
export class SscSideTabsComponent implements AfterViewInit, OnDestroy {
  private scrollSubscription: Subscription;

  state: string = 'hidden';
  selected: number = 1;

  @Input() tabData: Array<any>;

  names: string[];
  // @Output() onChange: EventEmitter<any> = new EventEmitter();

  // @Input() quote: string;
  // @Input() person: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private scrollDispatcher: ScrollDispatcher
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  setSelected(new_selected: number) {
    this.selected = new_selected;
    // this.onChange.emit(new_selected);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = 'show';
      this.changeDetectorRef.detectChanges();
    }, cInitDelay);
    // this.onChange.emit(this.selected);
  }

  // ngOnInit() {
  // }

  private checkState(element: ElementRef) {
    const winOffsetHeight: number = element.nativeElement.offsetHeight;
    const offset: number = this.state === 'show' ? cHideOffset : cShowOffset;
    const elOffsetTop: number = this.el.nativeElement.getBoundingClientRect()
      .top;
    // const elOffsetTop:number = this.el.nativeElement.offsetTop;
    // const winScrollTop:number = element.nativeElement.scrollTop;

    // this.state = (elOffsetTop + offset < winScrollTop + winOffsetHeight) ? 'show' : 'hidden';
    this.state = elOffsetTop - winOffsetHeight + offset > 0 ? 'hidden' : 'show';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ssc-breadcrumb',
  templateUrl: './ssc-breadcrumb.component.html',
  styleUrls: ['./ssc-breadcrumb.component.scss']
})
export class SSCBreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'title';
  readonly home = { icon: 'pi pi-home', url: 'home' };
  menuItems: object[];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.router.routerState.root);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: any[] = []
  ): object[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');
      if (routeURL !== '' && !child.snapshot.data['abstract']) {
        url += `/${routeURL}`;
      }

      const label =
        child.snapshot.data[SSCBreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!isNullOrUndefined(label)) {
        breadcrumbs.push({ label, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}

import {
  Component,
  ChangeDetectorRef,
  Input,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';

// const cBorderWidthInit = '1px';                 // string:px          Initial border width
// const cBorderWidthHover = '2px';                // string:time        Hover border width
// const cZIndexHover = 99;                        // number:any         z-index of picture on hover
// const cScaleHover = '1.05';                     // string:scale       Scale of picture on hover

// /* Colorize-zoom Container */
// .img-hover-zoom--colorize img {
//   transition: transform .5s, filter 1.5s ease-in-out;
//   filter: grayscale(100%);
// }

// /* The Transformation */
// .img-hover-zoom--colorize:hover img {
//   filter: grayscale(0);
//   transform: scale(1.1);
// }

@Component({
  selector: 'ssc-photo-link',
  templateUrl: './ssc-photo-link.component.html',
  styleUrls: ['./ssc-photo-link.component.scss'],
  animations: [
    trigger('photoLinkAnim', [
      state(
        'none, void',
        style({
          transform: 'scale(1)',
          filter: 'grayscale(100%)'
        })
      ),
      state(
        'mouseover',
        style({
          transform: 'scale(1.1)',
          filter: 'grayscale(0)'
        })
      ),
      transition('none => mouseover', [
        animate(
          '2000ms ease-in-out',
          style({
            transform: 'scale(1.1)',
            filter: 'grayscale(0)'
          })
        )
      ]),
      transition('mouseover => none', [
        animate(
          '2000ms ease-in-out',
          style({
            transform: 'scale(1)',
            filter: 'grayscale(100%)'
          })
        )
      ])
    ])
  ]
})
export class SscPhotoLinkComponent {
  @Input() src: string;
  @Input() text: string;
  @Input() path: string;

  state: string = 'none';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  loadLink() {
    this.router.navigateByUrl(this.path);
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.state = 'mouseover';
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.state = 'none';
    this.changeDetectorRef.detectChanges();
  }
}

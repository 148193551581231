import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full'
  },
  {
    path: 'landing-page',
    loadChildren: () =>
      import('./features/landing-page/landing-page.module').then(
        m => m.LandingPageModule
      )
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home-page/home-page.module').then(
        m => m.HomePageModule
      )
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./features/staff/staff.module').then(m => m.StaffModule)
  },
  {
    path: 'to-play',
    loadChildren: () =>
      import('./features/to-play/to-play.module').then(m => m.ToPlayModule)
  },
  {
    path: 'to-connect',
    loadChildren: () =>
      import('./features/to-connect/to-connect.module').then(
        m => m.ToConnectModule
      )
  },
  {
    path: 'lend-a-hand',
    loadChildren: () =>
      import('./features/lend-a-hand/lend-a-hand.module').then(
        m => m.LendAHandModule
      )
  },
  {
    path: '**',
    redirectTo: 'landing-page'
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

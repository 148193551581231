import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  Inject
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';

import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';
import { Subscription } from 'rxjs';

const cOpacity = 1; // number:0 - 1       Opacity of the divider when shown
const cShowDelay = '400ms'; // string:time        Fade in time of the divider
const cHideDelay = '200ms';
const cTopOffsetShow = 100; // number:pixels      Number of pixels below the top to hide divider
const cZIndex = 10;

@Component({
  selector: 'ssc-top-button',
  templateUrl: './ssc-top-button.component.html',
  styleUrls: ['./ssc-top-button.component.scss'],
  animations: [
    trigger('topButtonAnim', [
      state(
        'hidden, void',
        style({
          opacity: 0,
          'z-index': -1
        })
      ),
      state(
        'show',
        style({
          opacity: cOpacity,
          'z-index': cZIndex
        })
      ),
      transition('hidden => show', [
        animate(
          cShowDelay,
          style({
            opacity: cOpacity,
            'z-index': cZIndex
          })
        )
      ]),
      transition('show => hidden', [
        animate(
          cHideDelay,
          style({
            opacity: 0,
            'z-index': -1
          })
        )
      ])
    ])
  ]
})
export class SscTopButton implements OnDestroy {
  private scrollSubscription: Subscription;

  state: string = 'hidden';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  private checkState(element: ElementRef) {
    const winScrollTop: number = element.nativeElement.scrollTop;

    this.state = cTopOffsetShow < winScrollTop ? 'show' : 'hidden';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }

  toTop() {
    this.document.documentElement
      .getElementsByTagName('mat-sidenav-content')[0]
      .scrollTo({ top: 0, behavior: 'smooth' });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faUserCircle,
  faPowerOff,
  faCog,
  faPlayCircle,
  faRocket,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook,
  faQuoteLeft,
  faAsterisk,
  faPhone,
  faMap,
  faAt,
  faChevronUp,
  faChevronRight,
  faHome
} from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faMediumM,
  faTwitter,
  faInstagram,
  faYoutube,
  faFacebook
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faBars,
  faUserCircle,
  faPowerOff,
  faCog,
  faRocket,
  faPlayCircle,
  faGithub,
  faMediumM,
  faTwitter,
  faInstagram,
  faYoutube,
  faFacebook,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook,
  faQuoteLeft,
  faAsterisk,
  faPhone,
  faMap,
  faAt,
  faChevronUp,
  faChevronRight,
  faHome
);

import { RtlSupportDirective } from './rtl-support/rtl-support.directive';
import { IfChangesDirective } from './if-change/if-change.directive';

import { SscDividerComponent } from './ssc-divider/ssc-divider.component';
import { SscQuoteComponent } from './ssc-quote/ssc-quote.component';
import { SscPhotoComponent } from './ssc-photo/ssc-photo.component';
import { SscPhotoClusterComponent } from './ssc-photo-cluster/ssc-photo-cluster.component';
import { SscHeadingComponent } from './ssc-heading/ssc-heading.component';
import { SscTopTabsComponent } from './ssc-top-tabs/ssc-top-tabs.component';
import { SscSideTabsComponent } from './ssc-side-tabs/ssc-side-tabs.component';
import { SscContentContainerComponent } from './ssc-content-container/ssc-content-container.component';
import { SSCBreadcrumbComponent } from './ssc-breadcrumb/ssc-breadcrumb.component';
import { SscPhotoLinkComponent } from './ssc-photo-link/ssc-photo-link.component';
import { SscLinkComponent } from './ssc-link/ssc-link.component';
import { SscFourColumnsComponent } from './ssc-four-columns/ssc-four-columns.component';
import { SscThreeColumnsComponent } from './ssc-three-columns/ssc-three-columns.component';
import { SscBadgeComponent } from './ssc-badge/ssc-badge.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    RouterModule,

    TranslateModule,

    MatButtonModule,
    MatToolbarModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,

    FontAwesomeModule
  ],
  declarations: [
    RtlSupportDirective,
    IfChangesDirective,
    SscDividerComponent,
    SscQuoteComponent,
    SscPhotoClusterComponent,
    SscHeadingComponent,
    SscPhotoComponent,
    SscTopTabsComponent,
    SscSideTabsComponent,
    SscContentContainerComponent,
    SSCBreadcrumbComponent,
    SscPhotoLinkComponent,
    SscLinkComponent,
    SscFourColumnsComponent,
    SscThreeColumnsComponent,
    SscBadgeComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,

    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,

    FontAwesomeModule,

    RtlSupportDirective,
    IfChangesDirective,

    SscDividerComponent,
    SscQuoteComponent,
    SscPhotoClusterComponent,
    SscHeadingComponent,
    SscPhotoComponent,
    SscTopTabsComponent,
    SscSideTabsComponent,
    SscContentContainerComponent,
    SSCBreadcrumbComponent,
    SscPhotoLinkComponent,
    SscLinkComponent,
    SscFourColumnsComponent,
    SscThreeColumnsComponent,
    SscBadgeComponent
  ]
})
export class SharedModule {}

import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  Input
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';

import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';
import { Subscription } from 'rxjs';

const cOpacity = 1; // number:0 - 1       Opacity of the divider when shown
const cFadeDelay = '300ms'; // string:time        Fade in time of the divider
const cInitDelay = 300; // number:ms          Number of ms before showing the initial animation
const cHideOffset = 0; // number:pixels      Number of pixels below the top to hide divider
const cShowOffset = 35; // number:pixels      Number of pixels below the top to show divider
const cYTranslate = '30px'; // string:percent     Amount to slide up on show

@Component({
  selector: 'ssc-photo-cluster',
  templateUrl: './ssc-photo-cluster.component.html',
  styleUrls: ['./ssc-photo-cluster.component.scss'],
  animations: [
    trigger('photoClusterAnim', [
      state(
        'hidden, void',
        style({
          opacity: 0,
          transform: 'translateY(' + cYTranslate + ')'
        })
      ),
      state(
        'show',
        style({
          opacity: cOpacity,
          transform: 'translateY(0%)'
        })
      ),
      transition('hidden => show', [
        animate(
          cFadeDelay,
          style({
            opacity: cOpacity,
            transform: 'translateY(0%)'
          })
        )
      ]),
      transition('show => hidden', [
        animate(
          '0ms',
          style({
            opacity: 0,
            transform: 'translateY(' + cYTranslate + ')'
          })
        )
      ])
    ])
  ]
})
export class SscPhotoClusterComponent implements AfterViewInit, OnDestroy {
  @Input() clusterStyle: number;
  @Input() src1: string;
  @Input() src2: string;
  @Input() src3: string;

  state: string = 'hidden';
  private scrollSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private scrollDispatcher: ScrollDispatcher
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = 'show';
      this.changeDetectorRef.detectChanges();
    }, cInitDelay);
  }

  private checkState(element: ElementRef) {
    const winScrollTop: number = element.nativeElement.scrollTop;
    const winOffsetHeight: number = element.nativeElement.offsetHeight;
    const elOffsetTop: number = this.el.nativeElement.offsetTop;
    const offset: number = this.state === 'show' ? cHideOffset : cShowOffset;

    this.state =
      elOffsetTop + offset < winScrollTop + winOffsetHeight ? 'show' : 'hidden';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}

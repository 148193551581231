import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  Input
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';

// import {
//   animate,
//   style,
//   transition,
//   trigger,
//   state
// } from '@angular/animations';
import { Subscription } from 'rxjs';

// const cOpacity = 1; // number:0 - 1       Opacity of the divider when shown
// const cFadeDelay = '300ms'; // string:time        Fade in time of the divider
const cInitDelay = 300; // number:ms          Number of ms before showing the initial animation
const cTopOffset = 560;

@Component({
  selector: 'ssc-heading',
  templateUrl: './ssc-heading.component.html',
  styleUrls: ['./ssc-heading.component.scss']
  // animations: [
  //     trigger('headingAnim', [
  //       state('hidden, void', style({
  //           opacity: 0,
  //           transform: 'translateY(0%)'
  //       })),
  //       state('show', style({
  //           opacity: cOpacity,
  //           transform: 'translateY(-3%)'
  //       })),
  //       transition('hidden => show', [
  //         animate(cFadeDelay, style({
  //           opacity: cOpacity,
  //           transform: 'translateY(0%)' //This is broken
  //          })),
  //       ]),
  //     ])
  // ]
})
export class SscHeadingComponent implements AfterViewInit, OnDestroy {
  @Input() heading: string;

  private scrollSubscription: Subscription;

  state: string = 'hidden';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = 'show';
      this.changeDetectorRef.detectChanges();
    }, cInitDelay);
  }

  private checkState(element: ElementRef) {
    const winScrollTop: number = element.nativeElement.scrollTop;

    this.state = cTopOffset > winScrollTop ? 'show' : 'fixed';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}

import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  OnInit,
  Input
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';
import { MatSidenav } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { AppState } from '../core.state';
import { selectSettingsLanguage } from '../settings/settings.selectors';
import { actionSettingsChangeLanguage } from '../../core/settings/settings.actions';

const cTransparentOffset = 20; // number:pixels      Number of pixels below the top to show divider

@Component({
  selector: 'ssc-header',
  templateUrl: './ssc-header.component.html',
  styleUrls: ['./ssc-header.component.scss']
})
export class SscHeaderComponent implements OnInit, OnDestroy {
  private scrollSubscription: Subscription;

  @Input() inputSideNav: MatSidenav;
  @Input() hideHeader: boolean;

  // logoColour = require('../../../assets/rings-colour.png');

  languages: Array<string> = ['en'];
  isTransparent: boolean = true;
  bannerStatus: boolean = false;

  language$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  ngOnInit(): void {
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
  }

  private checkState(element: ElementRef) {
    const winScrollTop: number = element.nativeElement.scrollTop;

    this.isTransparent = winScrollTop < cTransparentOffset;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }

  hideBanner() {
    this.bannerStatus = false;
  }
}

import {
  Component,
  ChangeDetectorRef,
  Input,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';

@Component({
  selector: 'ssc-link',
  templateUrl: './ssc-link.component.html',
  styleUrls: ['./ssc-link.component.scss'],
  animations: [
    trigger('photoLinkAnim', [
      state(
        'none, void',
        style({
          transform: 'scale(1)'
        })
      ),
      state(
        'mouseover',
        style({
          transform: 'scale(1.1)'
        })
      ),
      transition('none => mouseover', [
        animate(
          '2000ms ease-in-out',
          style({
            transform: 'scale(1.1)'
          })
        )
      ]),
      transition('mouseover => none', [
        animate(
          '2000ms ease-in-out',
          style({
            transform: 'scale(1)'
          })
        )
      ])
    ])
  ]
})
export class SscLinkComponent {
  @Input() src: string;
  @Input() text: string;
  @Input() subtext: string;
  @Input() path: string;
  @Input() layout: string;

  state: string = 'none';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  loadLink() {
    this.router.navigateByUrl(this.path);
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.state = 'mouseover';
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.state = 'none';
    this.changeDetectorRef.detectChanges();
  }
}

import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';

import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';
import { Subscription } from 'rxjs';

const cFadeDelay = '300ms'; // string:time        Fade in time of the divider
const cInitDelay = 300; // number:ms          Number of ms before showing the initial animation
const cHideOffset = 0; // number:pixels      Number of pixels below the top to hide divider
const cShowOffset = 35; // number:pixels      Number of pixels below the top to show divider
const cYTranslate = '40px'; // string:percent     Amount to slide up on show

@Component({
  selector: 'ssc-content-container',
  templateUrl: './ssc-content-container.component.html',
  styleUrls: ['./ssc-content-container.component.scss'],
  animations: [
    trigger('contentContainerAnim', [
      state(
        'hidden, void',
        style({
          transform: 'translateY(' + cYTranslate + ')',
          opacity: 0
        })
      ),
      state(
        'show',
        style({
          transform: 'translateY(0%)',
          opacity: 1
        })
      ),
      transition('hidden => show', [
        animate(
          cFadeDelay + ' ease-out',
          style({
            transform: 'translateY(0%)',
            opacity: 1
          })
        )
      ]),
      transition('show => hidden', [
        animate(
          '0ms',
          style({
            transform: 'translateY(' + cYTranslate + ')',
            opacity: 0
          })
        )
      ])
    ])
  ]
})
export class SscContentContainerComponent implements AfterViewInit, OnDestroy {
  private scrollSubscription: Subscription;

  state: string = 'hidden';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private scrollDispatcher: ScrollDispatcher
  ) {
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.checkState(data.getElementRef());
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = 'show';
      this.changeDetectorRef.detectChanges();
    }, cInitDelay);
  }

  private checkState(element: ElementRef) {
    const winOffsetHeight: number = element.nativeElement.offsetHeight;
    const offset: number = this.state === 'show' ? cHideOffset : cShowOffset;
    const elOffsetTop: number = this.el.nativeElement.getBoundingClientRect()
      .top;
    // const elOffsetTop:number = this.el.nativeElement.offsetTop;
    // const winScrollTop:number = element.nativeElement.scrollTop;

    // this.state = (elOffsetTop + offset < winScrollTop + winOffsetHeight) ? 'show' : 'hidden';
    this.state = elOffsetTop - winOffsetHeight + offset > 0 ? 'hidden' : 'show';
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';

// export class MyErrorHandler implements ErrorHandler {
//   constructor() {}
//   handleError(error: Error) {
//    console.log(error.stack);
//   //  console.log(error.columnNumber);
//   }
// }

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,

    // app
    AppRoutingModule,

    // core & shared
    CoreModule,
    SharedModule
  ],
  declarations: [AppComponent],
  // providers: [
  //  {
  //   provide: ErrorHandler,
  //   useClass: MyErrorHandler,
  //  },
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {}
